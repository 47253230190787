import React from "react";
import cx from "classnames";
import { Trans } from "@elevio/kb-kit/lib/components/trans";
import LoginLogout from "./LoginLogout";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin';
import { faTv } from '@fortawesome/free-solid-svg-icons/faTv';
const CONTACT_US = 'https://www.carmel.in.gov/our-city/contact-us';
function Footer({ className }) {
    return (<footer className={cx("footer", className)} data-testid="footer">
      <div className="footer-wrapper">
        <p className="footer-copyright">
          &copy; {new Date().getFullYear()} Comcate, Inc.
        </p>
        <nav className="footer-nav">
          <a className="footer-link" target="_blank" href={CONTACT_US}>
            <Trans>Contact Us</Trans>
          </a>
          <a className="footer-link" target="_blank" href="https://www.youtube.com/user/CityofCarmel">
            <FontAwesomeIcon icon={faYoutube}/>
          </a>
          <a className="footer-link" target="_blank" href="https://www.facebook.com/CityofCarmel">
            <FontAwesomeIcon icon={faFacebookF}/>
          </a>
          <a className="footer-link" target="_blank" href="https://x.com/CITYOFCARMELIN">
            <FontAwesomeIcon icon={faTwitter}/>
          </a>
          <a className="footer-link" target="_blank" href="https://www.linkedin.com/company/city-of-carmel/">
            <FontAwesomeIcon icon={faLinkedin}/>
          </a>
          <a className="footer-link" target="_blank" href="https://carmeltv.carmel.in.gov/CablecastPublicSite/">
            <FontAwesomeIcon icon={faTv}/>
          </a>
          <a className="footer-link" target="_blank" href="https://www.instagram.com/cityofcarmelin/">
            <FontAwesomeIcon icon={faInstagram}/>
          </a>
          <LoginLogout className="footer-link"/>
        </nav>
      </div>
    </footer>);
}
export default Footer;
