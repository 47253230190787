export const DEFAULT_CRM_TOKEN = '2ysz1dvfru';
export const brandLogo = `${window.assetsLocation}/images/logo.svg`;
export const brandShowcaseUrl = `${window.assetsLocation}/images/showcase.svg`;
export const bannerUrl = `${window.assetsLocation}/images/banner.png`;
export const viewReportIconUrl = `${window.assetsLocation}/images/carousel.png`;
export const homeArticleIds = ['181'];
export const faqArticleIds = [
    "182",
    "184",
    "183",
    "185",
    "186",
    "187",
    "188",
    "3",
    "13",
    "14",
    "189"
];
